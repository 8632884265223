import React from "react"
import { Link } from "gatsby"
import styled from "@emotion/styled"
import { breakPoints, gutter, letterSpacing, toEm, toRem } from "../styles/utils"
import SEO from "../components/seo"
import Header from "../components/header"
import Container from "../components/container"
import Wrapper from "../components/wrapper"
import Footer from "../components/footer"

const HeroCopy = styled(Container)`
  align-items: center;
  text-align: center;
  min-height: 56vh;

  @media (min-width: ${breakPoints.medium}) {
    min-height: 52vh;
    padding-top: ${toRem(120)};
    padding-bottom: ${toRem(120)};
  }
`

const HeroWrapper = styled.div`
  ${gutter};

  @media (min-width: ${breakPoints.small}) {
    width: ${toRem(500)};
  }
  @media (min-width: ${breakPoints.medium}) {
    width: ${toRem(650)};
  }
  @media (min-width: ${breakPoints.large}) {
    width: ${toRem(740)};
  }
`
const HeroTitle = styled.h1`
  --title-font-size: ${toEm(32)};
  letter-spacing: ${letterSpacing(15)};
  line-height: 1;
  margin: 0;

  @media (min-width: ${breakPoints.small}) {
    --title-font-size: ${toEm(46)};
    letter-spacing: ${letterSpacing(15)};
  }
  @media (min-width: ${breakPoints.medium}) {
    --title-font-size: ${toEm(50)};
    letter-spacing: ${letterSpacing(15)};
    padding-top: 0;
    padding-bottom: 0;
  }
  @media (min-width: ${breakPoints.large}) {
    --title-font-size: ${toEm(65)};
    letter-spacing: ${letterSpacing(15)};
  }
`

const HeroDesc = styled.p`
  margin-bottom: 0;
  margin-top: ${toRem(15)};

  @media (min-width: ${breakPoints.medium}) {
    --body-font-size: ${toEm(24)};
    margin-top: ${toRem(25)};
    padding-right: ${toRem(30)};
    padding-left: ${toRem(30)};
  }
`

const NotFound = () => (
  <Wrapper>
    <SEO title="Page Not Found" />
    <Header />
    <main id="MainContent">
      <HeroCopy>
        <HeroWrapper>
          <HeroTitle>The page you’re looking for can’t be found.</HeroTitle>
          <HeroDesc>
            <Link to="/">Go Back Here</Link>
          </HeroDesc>
        </HeroWrapper>
      </HeroCopy>
    </main>
    <Footer />
  </Wrapper>
)

export default NotFound
